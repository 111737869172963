function joinUrl (base: string, relativeUri: string) {
  return [base, relativeUri.replace(/^\//, '')].join('/');
}

export default function useCreateLink () {
  const config = useRuntimeConfig();

  const createMedmasteryLink = (relativeUri: string) => joinUrl(config.public.publicWebsite, relativeUri);
  const createAppLink = (relativeUri: string) => joinUrl(config.public.drupalSevenUrl, relativeUri);

  return {
    createMedmasteryLink,
    createAppLink,
  };
}
