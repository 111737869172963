<script setup lang="ts">
import type { MenuItem } from './MNavigationItem.vue';

const props = defineProps<{
  items?: MenuItem[];
  activeItem?: string;
  fullscreen?: boolean;
}>();

const { items, activeItem } = toRefs(props);
</script>

<template>
  <ul class="m-navigation-bar">
    <template v-if="items">
      <MNavigationItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        :is-active="item.id === activeItem"
      />
    </template>
    <slot v-else />
  </ul>
</template>

<style lang="scss">
.m-navigation-bar {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0 1.5em;
  list-style: none;
}
</style>