<script setup lang="ts">
import UserProfileEmpty from '~/assets/images/user-profile-empty.svg';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { MenuItem } from "primevue/menuitem";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { SubscriptionType } from "~/composables/useAuthentication";
import { useClipboard } from "@vueuse/core";
import { GenericEvents } from '~/enums/tracking/tracking-events.enums';

const { dispatchTrackingEvent } = useTracking();
const { user, name } = useUserStore();
const { getImageUrl } = useCmsMedia();

const menuClicked = (url: string | undefined, text?: string | ((...args: any) => string) | undefined) => {
  dispatchTrackingEvent(GenericEvents.MenuClick, {
    link_url: url,
    click_text: text,
    menu_category: undefined,
  });
};

const actionsMenu = ref();
const actionsMenuItems: MenuItem[] | undefined = [
  {
    label: 'Profile Info',
    icon: 'fa-address-card',
    route: `/account/profile-info`,
    command: m => {
      menuClicked(m.item.route, m.item.label);
    },
  },
  {
    label: 'Subscription',
    icon: 'fa-circle-star',
    route: `/account/subscription`,
    command: m => {
      menuClicked(m.item.route, m.item.label);
    },
  },
  {
    label: 'Logout',
    icon: 'fa-door-open',
    command: m => {
      menuClicked(undefined, m.item.label);
      useAuthentication().logout();
    },
  },
];

const getIcon = (icon: string | undefined) => {
  return icon as object | Array<string> | string | IconDefinition;
};

const config = useRuntimeConfig();
const gitInfo = config.public.env !== 'production' ? config.public.gitInfo : null;
const { copy, copied } = useClipboard();

</script>

<template>
  <div>
    <Button
      v-if="user"
      severity="secondary"
      text
      class="flex items-center gap-4 p-2 lg:rounded-xl rounded-full"
      @click="(event) => {
        actionsMenu.toggle(event)
      }"
    >
      <div class="lg:flex hidden flex-col items-end">
        <div class="font-bold mb-1 text-neutral-70 text-nowrap">
          {{name}}
        </div>

        <div :class="['subscription', user?.subscription?.type]">
          <UserSubscriptionPill />
        </div>
      </div>

      <Avatar
        class="rounded-full bg-transparent outline outline-2 outline-neutral-30 w-[52px] h-[52px]"
        size="large"
      >
        <img
          v-if="user?.profilePicture?.path"
          :src="getImageUrl({
            location: user.profilePicture,
            metaData: {
              width: 256,
              height: 256,
              alt: user.name || '',
              title: user.name || ''
            }
          }, {
            width: 256,
            height: 256,
            output: 'webp'
          })"
          class="rounded-full w-full h-full object-cover"
          :alt="user?.name"
        >
        <UserProfileEmpty
          v-else
          class="user-profile-empty"
        />
      </Avatar>
    </Button>

    <Menu
      id="actions-menu"
      ref="actionsMenu"
      style="margin-top: .5rem !important"
      :model="actionsMenuItems"
      :popup="true"
      append-to="self"
    >
      <template #end>
        <div
          v-if="user?.subscription.type === SubscriptionType.basic"
          class="w-[100%] px-2 mt-2"
        >
          <NuxtLink to="/enroll/pro-yearly">
            <Button
              label="Upgrade to Pro"
              class="bg-yellow-50 text-neutral-80 font-bold text-bold border-none text-nowrap w-full justify-center"
            />
          </NuxtLink>
        </div>

        <div
          v-if="gitInfo"
          class="px-4 pb-2 pt-4"
        >
          <div
            class="bg-neutral-10 rounded-md text-14 px-2 py-1 cursor-pointer flex justify-between active:outline outline-neutral-50"
            @click="copy(gitInfo)"
          >
            <div>
              v <b>{{gitInfo}}</b>
            </div>

            <div>
              <FontAwesomeIcon
                v-if="!copied"
                :icon="['far', 'clipboard']"
              />
              <FontAwesomeIcon
                v-else
                icon="fa-check"
              />
            </div>
          </div>
        </div>
      </template>

      <template #item="{item, props}">
        <NuxtLink
          v-if="item.route"
          :to="item.route"
          v-bind="props.action"
          class="text-neutral-90"
        >
          <FontAwesomeIcon :icon="getIcon(item.icon)" />
          <span class="ml-2">{{item.label}}</span>
        </NuxtLink>

        <NuxtLink
          v-else
          :to="item.url"
          :target="item.target"
          v-bind="props.action"
          class="text-neutral-90"
        >
          <FontAwesomeIcon :icon="getIcon(item.icon)" />
          <span class="ml-2">{{item.label}}</span>
        </NuxtLink>
      </template>
    </Menu>
  </div>
</template>

<style scoped lang="scss">
.p-button:focus, .p-button.p-button-secondary:enabled:focus {
  box-shadow: none !important;
}
</style>