import { useWindowSize } from '@vueuse/core';

export default function useScreen () {
  const { width } = useWindowSize();

  function between (min: number, max?: number) {
    return width.value >= min && (max === undefined || width.value < max);
  }

  return {
    between,
  };
}
